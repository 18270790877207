import React from 'react'
import { Carousel } from 'antd';
import { useNavigate } from 'react-router-dom';

function Index() {
    const navigate = useNavigate()
    return (
        <div className='maininedxdiv' style={{display:"flex",alignItems:"center",marginTop:"50px"}}>
            {/* <img src="../ico.png" alt="" width={"100px"} style={{marginTop:"10px"}} /> */}

      <div><button className='button' style={{width:"1500px",height:"130px",borderRadius:"10px",gap:"50px",marginTop:"10px",fontSize:"25px",fontWeight:"bold"}} onClick={() => navigate("/form")}>Register your Microbiome kit</button></div>
      <div><button className='button' style={{width:"1500px",height:"130px",borderRadius:"10px",gap:"50px",marginTop:"30px",fontSize:"25px",fontWeight:"bold"}} onClick={() => navigate("/parasitologyform")}>Register your Parasitology kit</button></div>
      <div><button className='button' style={{width:"1500px",height:"130px",borderRadius:"10px",gap:"50px",marginTop:"30px",fontSize:"25px",fontWeight:"bold"}} onClick={() => navigate("/dnaform")}>Register your DNAMap form kit</button></div>
      <div><button className='button' style={{width:"1500px",height:"130px",borderRadius:"10px",gap:"50px",marginTop:"30px",fontSize:"25px",fontWeight:"bold"}} onClick={() => navigate("/foodsensitivityform")}>Register your Food Sensitivity kit</button></div>
      <div><button className='button' style={{width:"1500px",height:"130px",borderRadius:"10px",gap:"50px",marginTop:"30px",fontSize:"25px",fontWeight:"bold"}} onClick={() => navigate("/candidaform")}>Register your CandidaProfile kit</button></div>
      <div><button className='button' style={{width:"1500px",height:"130px",borderRadius:"10px",gap:"50px",marginTop:"30px",marginBottom:"30px",fontSize:"25px",fontWeight:"bold"}} onClick={() => navigate("/labrecieved")}>Kit Received Form</button></div>
            {/* <Carousel className='Carousel'>
                <div className='Carouseldiv'>
                    <div >
                        <h1>Step 1</h1>
                        <p>Now you have received your test kit, you will need to register it online. You can do so at guthealthlab.co.uk/form/ Or by scanning the QR code at the top right of this page.</p>
                        <p>Enter your kit code which is the 8 digit code with letters and numbers, you can find on the sticker in the top right of this page. You will also find your kit code on the sample collection tube.</p>
                        <p>Enter your email address and create an account - this will be used to download and view your results.</p>
                        <p>Once you have registered your kit, you can fill in your health questionnaire, this will take around 4 minutes. You will receive an email prompting you to complete this step.</p>
                    </div>
                </div >
                <div className='Carouseldiv'>
                    <div  className='mobbb' >
                        <div>
                            <h1>Step 2 - Take your test</h1>
                            <p>Your test kit includes:<br />
                                A sterile cotton swab<br />
                                A screw top tube containing a DNA stabiliser<br />
                                A biohazard specimen transport bag<br />
                                A return envelope to send your sample to the lab </p><br />
                            <p>     Collect a small stool sample next time you use the bathroom. </p>
                            <br />
                            <p>        Get ready to test: <br />
                                - Take the screw top lid off your tube <br />
                                - Open the sterile swab by tearing at the handle end</p>
                            <br />  <p>     Once you have taken a poo, wipe with toilet paper (use your first wipe) and keep the toilet paper in hand. <br />
                                Take out the swab and use to collect as much stool as possible from the paper Take the swab and mix it into the liquid in your tube for 30 seconds </p>
                            <br />       <p>   Important - you should see a colour change in the liquid to a brown colour. If the colour change is not dark and obvious, repeat the process until it is.</p>
                        </div>

                        <div>
                            <img src='t1.png' width={"100%"} />

                            <img src='t.png' width={"100%"} />
                        </div>

                    </div>
                </div>
                <div className='Carouseldiv'>
                    <div >
                        <h1>Step 3</h1>
                        <p>Once your liquid has changed colour, screw the lid onto the tube tightly and secure. Place your tube into the provided specimen transport bag and seal the bag.</p>
                        <br />
                        <h1>Step 4</h1>
                        <p>Once you have posted your sample to the lab, your results will be available within 3-4 weeks. You will receive the results from the provider you used to purchase the test.</p>

                    </div>

                </div>

            </Carousel> */}
        </div>
    )
}

export default Index
